var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid-image-presentation-cell" },
    [
      _vm.value
        ? _c("LazyImage", {
            attrs: {
              href: "multimedia/" + _vm.value + "/download/default",
              "object-fit": "contain",
              value: _vm.value
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }