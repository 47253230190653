var render = function(_h, _vm) {
  var _c = _vm._c
  return _c("span", {
    staticClass: "grid-suffix-presentation-cell",
    domProps: { textContent: _vm._s(_vm.props.suffix) }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }