var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CheckBox", {
    attrs: { value: _vm.value, disabled: _vm.disabled },
    nativeOn: {
      click: function($event) {
        $event.preventDefault()
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }