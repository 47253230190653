var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", {
    class: _vm.classes,
    domProps: {
      textContent: _vm._s(_vm.isPlaceholder ? _vm.placeholder : _vm.value)
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }