var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.classes,
      attrs: {
        tabindex: -1,
        row: _vm.row,
        column: _vm.column,
        copyable: _vm.copyable
      },
      on: { dblclick: _vm.onDoubleClick, keydown: _vm.onKeyDown }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }