var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "GridTableCell",
    {
      attrs: {
        column: 0,
        row: _vm.row,
        "editing-allowed": "",
        "edit-key-code": 32,
        selected: _vm.selected,
        disabled: _vm.disabled
      },
      on: { edit: _vm.onSelect },
      nativeOn: {
        mousedown: function($event) {
          return _vm.onSelect.apply(null, arguments)
        }
      }
    },
    [
      _c("GridRadioEditCell", {
        attrs: { value: _vm.selected, disabled: _vm.disabled }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }