var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "GridTableCell",
    {
      attrs: {
        "editing-allowed": "",
        disabled: _vm.disabled,
        "edit-key-code": 32,
        row: _vm.rowsOffset,
        column: 0
      },
      on: { edit: _vm.onSelectAll },
      nativeOn: {
        mousedown: function($event) {
          return _vm.onSelectAll.apply(null, arguments)
        }
      }
    },
    [
      _c("GridCheckEditCell", {
        attrs: { disabled: _vm.disabled, value: _vm.rowsSelectionState }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }