var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.component, {
    tag: "Component",
    attrs: {
      value: _vm.value,
      "row-index": _vm.rowIndex,
      "column-index": _vm.columnIndex,
      "column-id": _vm.columnId,
      "language-code": _vm.languageCode,
      data: _vm.filter
    },
    on: {
      "edit-filter-cell": _vm.onEditFilterCell,
      "filter-value": _vm.onFilterValueChange,
      "filter-clear": _vm.onFilterValueClear
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }