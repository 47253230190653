var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "GridTableCell",
    {
      attrs: {
        row: _vm.rowIndex,
        column: _vm.columnIndex,
        "data-cy": _vm.columnId,
        locked: true
      }
    },
    [
      _c(
        "div",
        {
          class: _vm.classes,
          on: { mouseenter: _vm.onMouseEnter, mouseleave: _vm.onMouseLeave }
        },
        [
          _c("GridTitleHeaderCell", {
            attrs: { title: _vm.title, hint: _vm.hint }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isActionsVisible,
                  expression: "isActionsVisible"
                }
              ],
              class: _vm.headerActionsClasses
            },
            [
              _c("IconArrowSort", {
                attrs: {
                  order: _vm.sortingOrder,
                  "fill-color": _vm.graphiteLightColor
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.onClickSort.apply(null, arguments)
                  }
                }
              }),
              _vm._v(" "),
              _vm.deletable
                ? _c("ActionIconButton", {
                    attrs: {
                      theme: _vm.secondaryPlainTheme,
                      size: _vm.tinySize,
                      options: _vm.contextualMenuItems
                    },
                    on: { focus: _vm.onSelectFocus, input: _vm.onSelectOption },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "icon",
                          fn: function(ref) {
                            var color = ref.color
                            return [
                              _c("IconDots", { attrs: { "fill-color": color } })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      786168029
                    )
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }