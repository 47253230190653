var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.component, {
    tag: "Component",
    attrs: {
      "data-cy": _vm.dataCyGenerator,
      column: _vm.columnIndex,
      "row-id": _vm.rowId,
      row: _vm.rowIndex,
      href: _vm.action.href,
      disabled: _vm.disabled,
      selected: _vm.selected
    },
    on: { action: _vm.onAction }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }