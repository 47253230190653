var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.component, {
    tag: "Component",
    attrs: {
      data: _vm.data,
      column: _vm.column,
      draft: _vm.draft,
      "row-id": _vm.rowId,
      "error-messages": _vm.errorMessages,
      "column-index": _vm.columnIndex,
      "row-index": _vm.rowIndex,
      "is-disabled": _vm.isDisabled,
      "is-draft": _vm.isDraft,
      "is-locked": _vm.isLocked,
      "is-copyable": _vm.isCopyable,
      "is-selected": _vm.isSelected
    },
    on: { "edit-cell": _vm.onEditCell, "cell-value": _vm.onCellValueChange }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }