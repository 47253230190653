var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ActionFab", {
    attrs: {
      theme: _vm.secondaryPlainTheme,
      size: _vm.smallSize,
      options: _vm.items,
      disabled: _vm.disabled
    },
    on: { input: _vm.onSelectValue },
    scopedSlots: _vm._u([
      {
        key: "option",
        fn: function(ref) {
          var option = ref.option
          return [
            _c(
              "ListElementDescription",
              [
                _c("ListElementTitle", {
                  attrs: { size: _vm.smallSize, title: option.text }
                })
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }