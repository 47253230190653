var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cell-resizer", style: _vm.styles }, [
    _vm.isResizing
      ? _c("div", {
          staticClass: "cell-resizer__focus-imitator",
          style: _vm.focusImitatorStyles
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "cell-resizer__content",
        on: { mousedown: _vm.initResizingDrag }
      },
      [
        _vm.isResizing
          ? _c("div", {
              ref: "border",
              staticClass: "cell-resizer__border",
              style: _vm.borderStyles
            })
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }